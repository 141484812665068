import React from 'react'
import styled from 'styled-components'
import PageContainer from '../components/PageContainer'

const ContactUs = () => {
    return (
        <PageContainer>
            <h1>Contact Us</h1>
            <p>If you would like to make your own custom experience, or are having trouble with the app, please reach out to us at <a href="mailto:contact@toastly.io">contact@toastly.io</a></p>
        </PageContainer>
    )
}

export default ContactUs