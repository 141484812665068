import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    padding: 1rem 1rem 0 1rem;
    @media (min-width: 768px) {
        padding: 2rem 25% 0 25%;
    }
`

interface PageContainerProps {
    children: ReactNode | Element[];
}

const PageContainer = function<T>({ children }: PageContainerProps) {
    return (
        <Container>
            <>
            { children }
            </>
        </Container>
    )
}

export default PageContainer