import React from 'react'
import PageContainer from '../components/PageContainer'

const Privacy = () => {
    return (
        <PageContainer>
            <h1>Privacy Policy</h1>
            <h2>Who we are</h2>
            <p><strong>Toastly: </strong>Our website address is: https://toastly.io.</p>
            <h2>Media</h2>
            <p>If you upload images to the website or app the media will be available to the host of the wedding / event. Toastly temporarily stores this media for the host until they download it and it is removed from Toastly’s servers. Toastly itself does not do anything with your media, but the host of the event may use the media in any way they choose. If you have questions about how your media will be used reach out to the host of the event.</p>
            <h2>Cookies</h2>
            <p>If you use the Toastly website you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again at a later time.</p>
            <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
            <h2>Data Usage</h2>
            <h3>Collection</h3>
            <p>We collect anonymized usage data. This includes device ids, ip address, app usage stats, and crash reports. We collect this data in order to help improve the product by seeing what parts of the app are being used and if any crashes are happening. We collect the default data used by Google Analytics / Crashlytics.</p>
            <p>We do not sell any collected data, we're just trying to see how many people use the app and if there are bugs we need to fix. Our goal is to collect as little data as possible.</p>
            <p>We do not collect your personal data.</p>
            <h2>Who we share your data with</h2>
            <p>Since we use Google Analytics and Crashlytics products to collect user data this means the data is ultimately stored in Google's products which is an industry standard.</p>
            <div style={{ height: '40px' }} />
        </PageContainer>
    )
}

export default Privacy