import React from 'react'
import DownloadButton from '../components/DownloadButton'
import styled from 'styled-components'
import Constant from '../utils/constant'

export interface AppPreviewInfo {
    title: string
    description: string
    imageSource: string
}

const Screenshot = styled.img`
    max-width: 400px;
    width: 100%;
    height: auto;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
`

const ScreenshotContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
`

const InfoContainer = styled.div`
    padding: 0.5rem;
    @media (min-width: 768px) {
        flex: 0.4;
        padding: 0px;
    }
`

const Detail = styled.p`
    font-size: 20px;
    white-space: pre-line;
    @media (min-width: 768px) {
        font-size: 24px;
    }
`

const Title = styled.p`
    font-size: 24px;
    font-weight: 400;
    @media (min-width: 768px) {
        font-size: 30px;
    }
`

const DownloadContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    /* padding-bottom: 1rem; */
`

const AppPreview = (info: AppPreviewInfo) => {

    const description = () => {
        return info.description ?
            ( <Detail>{info.description}</Detail> ) : undefined
    }

    const infoView = () => {
        return (
            <InfoContainer>
                <Title>{info.title}</Title>
                { description() }
                <DownloadContainer>
                    <DownloadButton client='ios' url={Constant.iosStoreLink} />
                    <DownloadButton client='android' url={Constant.androidStoreLink} />
                </DownloadContainer>
            </InfoContainer>
        )
    }

    return (
        <ScreenshotContainer>
            { infoView() }
            <Screenshot src={info.imageSource} />
        </ScreenshotContainer>
    )
}

export default AppPreview