import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import toastlyLogo from '../assets/toastly-icon.png'
import styled from 'styled-components'

export interface NavItem {
    path: string
    title: string
}

interface NavbarProps {
    title: string
    items: NavItem[]
    onRenderItem: (item: NavItem) => JSX.Element
    onClickItem: (item: NavItem) => void
    onClickTitle: () => void
    onToggleMenu: () => void
    onCloseMenu: () => void
    isMenuOpen: boolean
}

const BrandImg = styled.img`
    margin-right: 0.5rem;
    border-radius: 0.4rem;
`

const AppNavbar = ({ isMenuOpen, title, items, onRenderItem, onClickTitle, onClickItem, onToggleMenu, onCloseMenu }: NavbarProps) => {
    const toggleMenu = () => {
        onToggleMenu()
    }

    const handleClose = () => {
        onCloseMenu()
    }

    const handleOnClick = (item: NavItem) => {
        onClickItem(item)
    }

    const expand = 'md'
    const navLinks = items.map((item) => {
        return (
            <Nav.Link key={item.path} onClick={() => handleOnClick(item)}>
                { onRenderItem(item) }
            </Nav.Link>
        )
    })
    return (
        <Navbar expand={expand} sticky="top" bg="light">
            <Container fluid>
                <Navbar.Brand onClick={onClickTitle} style={{ cursor: 'pointer', color: '#568EA6', fontWeight: 500, }}>
                    <BrandImg
                        src={toastlyLogo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Toastly logo"
                    />
                    {title}
                </Navbar.Brand>
                <Navbar.Toggle onClick={toggleMenu} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    show={isMenuOpen}
                    onHide={handleClose}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            {/* Menu */}
                            <a onClick={onClickTitle}>Toastly</a>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            { navLinks }
                            {/* <Nav.Link href="#action1">Home</Nav.Link>
                            <Nav.Link href="#action2">Link</Nav.Link>
                            <NavDropdown
                                title="Dropdown"
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                            >
                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action5">
                                    Something else here
                                </NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default AppNavbar