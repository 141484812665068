type APIPath = '/user' | '/notification' | '/wedding'

class Constant {
    static apiBase = "api/"

    static apiURL = (path: APIPath): string => {
        return this.apiBase + path
    }

    static iosStoreLink = 'https://apps.apple.com/ca/app/toastly/id1645803647'
    static androidStoreLink = 'https://play.google.com/store/apps/details?id=com.sparkcode.toastly'
}

export default Constant