import React, { useEffect, useState } from 'react'
import { NavLink, NavLinkProps, Route, Routes, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Home from './pages/Home'
import ContactUs from './pages/ContactUs'
import Login from './pages/Login'
import Privacy from './pages/Privacy'
import Navbar, { NavItem } from './components/Navbar'

interface LinkProps {
  item: NavItem
  isActive: boolean
}

interface NavItemProps {
  title: string
  isActive: boolean
}
const NavButton = ({ title, isActive }: NavItemProps) => {
  if (isActive) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'red' }}>
          {/* <p style={{ display: 'inline-block', background: 'blue' }}> */}
            { title }   
          {/* </p> */}
      </div>
    )
  } else {
    return (
      <p>
        { title }   
      </p>
    )
  }
}

const Link = ({ item, isActive }: LinkProps) => {
  const defaultStyle = {
    textDecoration: 'none',
    color: '#568EA6',
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    paddingLeft: '1rem',
  }
  
  const activeStyle = {
    ...defaultStyle,
    textUnderlinePosition: 'under',
    textDecoration: 'underline'
  }

  return (
    <p style={ isActive ? activeStyle : defaultStyle }>
        { item.title }
    </p>
  )

  // return (
  //   <NavLink 
  //     to={item.path} 
  //     key={item.title} 
  //     onClick={onClick}
  //     style={({ isActive }) => isActive 
  //       ? activeStyle : defaultStyle}
  //   >
  //     { item.title }
  //   </NavLink>
  // )
}

const App = () => {

  const [menuOpen, setMenuOpen] = useState(false)
  const [selectedLink, setSelectedLink] = useState('/')
  const navigate = useNavigate()

  const toggleMenu = () => {
    console.log(`toggle menu`)
    setMenuOpen(!menuOpen)
  }

  const navItems = [
    {
      path: '/contact',
      title: 'Contact'
    },
    // TODO: move privacy policy to the footer
    {
      path: '/privacy-policy',
      title: 'Privacy Policy'
    },
    // {
    //   path: '/login',
    //   title: 'Login'
    // }
  ]

  const onRenderItem = (item: NavItem): JSX.Element => {
    const isActive = item.path === selectedLink
    return (
      <Link isActive={isActive} item={item} />
    )
  }

  const onClickTitle = () => {
    setMenuOpen(false)
    setSelectedLink('/')
    navigate('/')
  }

  const onClickItem = (item: NavItem) => {
    setSelectedLink(item.path)
    setMenuOpen(false)
    navigate(item.path)
  }

  const onToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const onCloseMenu = () => {
    setMenuOpen(false)
  }

  return (
    <>
      <Navbar 
        title='Toastly'
        items={navItems}
        onRenderItem={onRenderItem}
        onToggleMenu={onToggleMenu}
        onCloseMenu={onCloseMenu}
        onClickTitle={onClickTitle}
        onClickItem={onClickItem}
        isMenuOpen={menuOpen}
      />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/login' element={<Login />} />
        <Route path='/privacy-policy' element={<Privacy />} />
      </Routes>
    </>
  )
}

export default App
