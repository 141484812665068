import React from 'react'
import styled from 'styled-components'
import appstoreLogo from '../assets/appstore.svg'
import playstoreLogo from '../assets/playstore.png'

interface DownloadButtonProps {
    client: 'ios' | 'android'
    url: string
}

const StyledButton = styled.a`
    height: 50px;
    display: inline-block;
`

const FillImage = styled.img`
    height: 100%;
    /* width: 100%; */
    width: auto;
    object-fit: contain;
`

const DownloadButton = ({ client, url }: DownloadButtonProps) => {
    const imgSrc = client === 'ios' ? appstoreLogo : playstoreLogo
    const onClick = () => {
        console.log('open new tab to app store')
    }

    return (
        <StyledButton href={url} target="_blank">
            <FillImage src={imgSrc} />
        </StyledButton>
    )
}

export default DownloadButton