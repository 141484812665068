import React, { useEffect } from 'react'
import logo from '../logo.svg'
import '../App.css'
import Constant from '../utils/constant'
import AppScreenHighlight, { AppScreenInfo } from '../components/AppScreenHighlight'
import AppPreview, { AppPreviewInfo } from '../components/AppPreview'
import itenerarySrc from '../assets/screen_itenerary.png'
import landingSrc from '../assets/screen_landing_1.png'
import weddingPartySrc from '../assets/screen_wedding_party.png'
import appstoreSrc from '../assets/appstore.svg'
import DownloadButton from '../components/DownloadButton'

const Home = () => {

    // useEffect(() => {
    //   fetch(Constant.apiURL('/user'), {
    //     method: 'GET'
    //   })
    //   .then((response) => {
    //     console.log('success!')
    //   })
    //   .catch((error) => {
    //     console.log(`error fetching: ${error}`)
    //   })
    // }, [])
    const appDescription = `Toastly enables you to privately share your wedding details with your guests from itneraries, registries, maps, and more, all in one place. Simply customize the app interface, then invite your guests.

    Guests can RSVP, upload photos from the event, opt in to receive push notifications, and much more. Download Toastly - the only personalized app for your wedding guests.
    `

    const screenData: AppPreviewInfo[] = [
        {
            title: 'Appify Your Wedding',
            description: appDescription,
            imageSource: landingSrc
        },
        // {
        //     title: 'Itinerary',
        //     imageSource: itenerarySrc
        // },
        // {
        //     title: 'Transportation & Map',
        //     imageSource: logo
        // },
        // {
        //     title: 'Food & Drink Menus',
        //     imageSource: logo
        // },
        // {
        //     title: 'Image & Video Upload',
        //     imageSource: logo
        // },
        // {
        //     title: 'Wedding Party Info',
        //     imageSource: logo
        // },
        // {
        //     title: 'Custom Features',
        //     description: 'Many of the pre-defined components can be used in ways that fit your needs. Custom components can also be created!',
        //     imageSource: logo
        // }
    ].map((value, index) => {
        return {
            ...value,
            alignment: index % 2 === 0 ? 'left' : 'right'
        }
    })

    const screenItems = screenData.map((item) => {
        return (
            <AppPreview key={item.title} {...item}/>
        )
    })
      
    return (
      <div className="App" style={{ padding: '1rem' }}>
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload!
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}
        {/* <h2 style={{ padding: '1rem' }}>Features</h2> */}
        {/* <div style={{ height: '1rem'}} /> */}
        {/* <DownloadButton client='ios' url='https://google.com' />
        <DownloadButton client='android' url='https://google.com' /> */}
        { screenItems }
      </div>
    );
  }

  export default Home